<template>
  <projects-layout :projects="projects">
    <template v-slot:project="{ project }">
      <folder-project :project="project"></folder-project>
    </template>
  </projects-layout>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("folder");

export default {
  name: "FolderProjects",
  components: {
    ProjectsLayout: () => import("@/components/project/ProjectsLayout"),
    FolderProject: () => import("@/views/folder/FolderProject")
  },
  computed: {
    ...mapState({ projects: "projects" })
  }
};
</script>
